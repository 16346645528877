export const app = {
  port: process.env.PORT || '3000',
  environment: process.env.NODE_ENV || 'development',
  strapiUrl: process.env.REACT_APP_ONBOARDING_STRAPI || 'http://localhost:1337',
  zendeskUrl:
    process.env.REACT_APP_ZENDESK_URL || 'https://paradisemobile.zendesk.com',
  zendeskAccount: process.env.REACT_APP_ZENDESK_EMAIL,
  zendeskApiToken: process.env.REACT_APP_ZENDESK_API_TOKEN,
  bermudaUrl:
    process.env.REACT_APP_GLOBAL_REDIRECTION_BM || 'https://paradisemobile.bm',
  caymanUrl:
    process.env.REACT_APP_GLOBAL_REDIRECTION_KY || 'https://paradisemobile.ky',
  websiteLocation: process.env.REACT_APP_WEBSITE_LOCATION || 'GLOBAL',
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  hotjarId: process.env.REACT_APP_HOTJAR_ID,
  hotjarSv: process.env.REACT_APP_HOTJAR_SV,
}
